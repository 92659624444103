import React from 'react';
import { useSSRSelector } from '@/redux/ssrStore';
import { IS_DESKTOP } from 'src/utils/checkClientDeviceType';

const StoreNameAndLogo = ({ logoImage = '', storeName = '' }) => {
  return (
    <section className="tw-flex tw-items-center tw-gap-16 tw-p-20">
      {!!logoImage && (
        <div className="tw-flex tw-items-center tw-rounded-md tw-border tw-border-solid tw-border-[#111C3626]">
          <img
            src={logoImage}
            height={40}
            width={40}
            className="tw-flex tw-items-center"
          />
        </div>
      )}
      <div className="tw-flex tw-items-center">
        <h2 className="tw-m-0 tw-font-inter tw-text-18 tw-font-semibold tw-text-newBlack">
          {storeName}
        </h2>
      </div>
    </section>
  );
};

const HeadingSection = () => {
  return (
    <section className="tw-mt-18 tw-flex-grow tw-p-20 tw-font-inter tw-font-bold tw-text-newBlack">
      <h1 className="tw-m-0 tw-max-w-[70%] tw-text-[52px]">Coming Soon...</h1>
      <p className="tw-m-0 tw-mt-24 tw-text-16 tw-font-normal tw-opacity-60">
        We're almost there. Stay tuned!
      </p>
    </section>
  );
};

const ImageSection = () => {
  const desktopImg = 'https://cdn.dotpe.in/longtail/custom_website/7593060/ag9VV4xL.gif';
  const mobileImg = 'https://cdn.dotpe.in/longtail/custom_website/7593060/vJV1GIrD.gif';

  return (
    <div className="tw-flex tw-items-end tw-justify-end">
      <img src={IS_DESKTOP ? desktopImg : mobileImg} className="tw-max-h-[500px]" />
    </div>
  );
};

const LaunchingSoon = () => {
  const { storeData } = useSSRSelector((state) => ({
    storeData: state.storeReducer.store,
  }));

  const { logo_image, store_name } = storeData;
  return (
    <main className="tw-flex tw-h-screen tw-flex-col tw-bg-white">
      <StoreNameAndLogo logoImage={logo_image} storeName={store_name} />
      <HeadingSection />
      <ImageSection />
    </main>
  );
};

export default LaunchingSoon;
